import React from "react";
import Info from './Info.js';

class Cover extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            currentCoverId: -1,
            currentTopicId: -1,
            currentItems: props.currentItems,
            coverSize: props.coverSize,
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState(nextProps);
    }

    render ()
    {
        const covers = [];
        let isOdd = true;
        let containerClassName = '';

        let coverPath;
        if (this.state.coverSize === "small") {
            coverPath = "thumbs/small/";
        } else {
            coverPath = "thumbs/large/";
            containerClassName = "big";
        }

        this.state.currentItems.map((j) => {

            if(global.items[j] === undefined) {
                return j;
            }

            const item = global.items[j];

            let className = 'cover';
            if (isOdd) {
                className += ' cover-odd';
            } else {
                className += ' cover-even';
            }

            if (item.landscape) {
                className += ' cover-landscape';
            } else {
                className += ' cover-portrait';
            }

            if (this.state.currentCoverId !== -1 && this.state.currentCoverId !== j) {
                className += ' dim';
            }

            if(window.navigator.userAgent.includes("Chrome/63")) {
                className += ' chrome63-fix';
            }

            covers.push(

                <div id={"cover-"+ j} className={className} key={"cover-"+j} onClick={() => this.selectCover(j)}>
                    <div className="middle">
                        <img src={coverPath+item.id+".jpg?t="+ item.mTime} className="cover-image" alt={item.title} />
                    </div>
                </div>
            );

            isOdd = !isOdd;

            return j;
        });

        return (
            <div>
                <div id="cover-container" className={containerClassName} onClick={(e) => this.deselectCover(e)}>
                    {covers}
                    <div id="end-margin" />
                </div>
                <Info currentCoverId={this.state.currentCoverId} />
            </div>
        )
    }


    selectCover(coverId)
    {
        global.ee.emit('select-cover', coverId);
    }

    deselectCover(event)
    {
        if (event.target === event.currentTarget) {
            global.ee.emit('select-cover', -1);
        }

    }

}

export default Cover;

import React from "react";

class JournalLendingInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            item: props.item,
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState(nextProps);
    }

    render ()
    {
        let shelfNbr = this.state.item.in_reading_room;
        let archiving = this.state.item.archiving;
        let swissdox = this.state.item.swissdox;

        var journalShelf = '';
        var journalShelfText = '';
        if (shelfNbr !== 0) {
            journalShelf = <div id="journal-shelf" className={"shelf-"+shelfNbr}>Regal</div>;
            journalShelfText = <div id="journal-shelf-text">Die aktuelle Ausgabe finden Sie im Lesesaal.</div>;
        }


        var journalLendingText = '';
        if (archiving === true) {
            if (shelfNbr === 0) {
                journalLendingText = 'Fragen Sie an der Ausleihe nach den Ausgaben.';
            } else {
                journalLendingText = 'Fragen Sie an der Ausleihe nach älteren Ausgaben.';
            }
        } else if (swissdox === true) {
            journalLendingText = 'Ältere Artikel sind über „Swissdox“ abrufbar, fragen Sie an der Ausleihe.';
        }



        return (
            <div id="journal-lending-info" className="info-box">
                <div id="journal-lending-text" className="info-text">
                    {journalShelfText}
                    {journalLendingText}
                </div>
                {journalShelf}
            </div>
        );
    }



}

export default JournalLendingInfo;

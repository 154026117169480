class TouchDevice  {

    static isTouchDevice() {

        if (window.location.hostname.includes('notouch')) {
            return false;
        }

        if (window.location.hostname.includes('withtouch')) {
            return true;
        }

        return 'ontouchstart' in window;
    }

}

export default TouchDevice;

import React from "react";

class TopicBar extends React.Component {


    constructor(props) {

        super(props);

        this.state = {
            currentTopicId: props.currentTopicId,
            currentCoverId: props.currentCoverId,
            topicCount: props.topicCount,
        };
    }


    componentWillReceiveProps(nextProps) {
        this.setState(nextProps);
    }

    render ()
    {
        return (
            <div>
                {this.topicLegend()}
                {this.topics()}
            </div>
        )
    }

    topicLegend()
    {
        return <div id="topics-legend">Sachgruppen</div>;
    }

    topics()
    {
        let currentCoverTopics = undefined;
        if (this.state.currentCoverId !== -1) {
            currentCoverTopics = global.items[this.state.currentCoverId].topics
        }

        return (
            <nav id="topics">
                {global.topics.map((element, i) => {
                    return this.item(i+1, element, currentCoverTopics);
                })}
            </nav>
        );
    }

    item(topicId, name, currentCoverTopics)
    {
        let className = "topic";
        let amount = "";

        if (Array.isArray(currentCoverTopics)) {

            if (currentCoverTopics.includes(topicId)) {
                className += " selected";
            } else {
                className += " inactive";
            }

        } else {

            amount = this.state.topicCount[topicId];

            if (this.state.currentTopicId !== -1) {
                if (this.state.currentTopicId === topicId) {
                    className += " selected";
                } else {
                    className += " inactive";
                }
            }

        }



        var key = "topic-" + topicId;

        return (
            <div className={className} id={key} key={key} onClick={() => this.toggleSelect(topicId)}>
                <div className="amount">{amount}</div>
                <div className="name" dangerouslySetInnerHTML={{__html: name}} />
            </div>
        );
    }


    toggleSelect(topicId) {

        if (topicId === this.state.currentTopicId) {
            global.ee.emit('update-topic', -1);
        } else {
            global.ee.emit('update-topic', topicId);
        }

    }

}

export default TopicBar;

import React from "react";
import { QRCode } from 'react-qr-svg';
import TouchDevice from '../TouchDevice.js';

class JournalOnlineInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            item: props.item,
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState(nextProps);
    }

    render() {
        if (TouchDevice.isTouchDevice()) {
            return this.renderTouch();
        } else {
            return this.renderDefault();
        }
    }

    renderTouch ()
    {
        let onlineText;
        let printOrOnline = this.state.item.print_or_online;
        let swissdox = this.state.item.swissdox;
        let onlineLink = '';


        if (swissdox === true ) {
            onlineText = 'Dieser Titel ist über die Datenbank „Swissdox“ essentials elektronisch verfügbar.';
            onlineLink = 'https://essentials.swissdox.ch/';
        } else {
            if (printOrOnline === "Print") {
                onlineText = "Mehr Informationen auf dem Nebis-Recherche-Portal:";
            } else if (printOrOnline === "Print und Online" ) {
                onlineText = "Diese Zeitschrift können Sie auch online lesen:";
            } else {
                onlineText =  "Diese Zeitschrift können Sie online lesen:";
            }
            onlineLink = "https://sozarch.ch/"+this.state.item.id;
        }

        return (
            <div id="info-online" className="info-box">
                <div className="info-text">
                    <div id="online-text">{onlineText}</div>
                    <div id="online-link" className="tiny-url">
                        {onlineLink}
                    </div>
                </div>

                <QRCode
                    bgColor="#FFFFFF"
                    fgColor="#000000"
                    level="Q"
                    style={{ width: 84 }}
                    value={onlineLink}
                />

            </div>
        )
    }


    renderDefault ()
    {
        let idPrint = this.state.item.idP;
        let idOnline = this.state.item.idO;
        let url = this.state.item.url;
        let links = [];
        if (idPrint !== null && idOnline !== null) {
            links.push(
                <a key="link-aleph-print" className="link link-aleph-print" href={this.getAlephUrl(idPrint)}
                   target="_blank"  rel="noopener noreferrer" >Rechercheportal (Druck-Ausgabe)</a>)
            ;
            links.push(
                <a key="link-aleph-online" className="link link-aleph-online" href={this.getAlephUrl(idOnline)}
                   target="_blank"  rel="noopener noreferrer">Rechercheportal (Online-Ausgabe)</a>
            );
        } else if (idPrint !== null) {
            links.push(
                <a key="link-aleph-print" className="link link-aleph-online" href={this.getAlephUrl(idPrint)}
                   target="_blank"  rel="noopener noreferrer">Rechercheportal</a>
            );
        } else if (idOnline !== null) {
            links.push(
                <a key="link-aleph-online" className="link link-aleph-online" href={this.getAlephUrl(idOnline)}
                   target="_blank"  rel="noopener noreferrer" >Rechercheportal</a>
            );
        }

        if (url !== null) {
            links.push(
                <a key="link-direct" className="link link-direct" href={url} target="_blank"  rel="noopener noreferrer">
                    Direkt zur Zeitschrift
                </a>
            );
        }


        return (
            <div id="info-online" className="info-box">
                <div className="info-text">
                    {links}
                </div>
            </div>
        )
    }

    getAlephUrl(sysnr) {
        sysnr = "000000000" + sysnr;
        sysnr = sysnr.substr(sysnr.length - 9);

        return "https://www.recherche-portal.ch/primo-explore/fulldisplay?vid=ZAD&docid=ebi01_prod" + sysnr;
    }

}

export default JournalOnlineInfo;

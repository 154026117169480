import React from "react";
import TouchDevice from './TouchDevice.js';

class InfoIntro extends React.Component {

    render ()
    {
        if (!TouchDevice.isTouchDevice()) {
            return '';
        }

        let journalsNumber = global.items.length;
        return (
            <div id="info-container">

                <div id="handling-info">
                    <div id="teaser" className="info-box">
                        Entdecken Sie <span id="journals-number">{journalsNumber}</span> Zeitschriften!
                    </div>

                    <div id="scroll" className="gesture-info-box info-box">
                        <div>
                            Weitere Titel anzeigen:<br />
                            über den Bildschirm<br />
                            wischen
                        </div>
                    </div>

                    <div id="click" className="gesture-info-box info-box">
                        <div>
                            Information zum Titel:<br />
                            Zeitschrift antippen
                        </div>
                    </div>

                </div>
            </div>
        )
    }

}

export default InfoIntro;

import React from "react";
import SearchBar from "./SearchBar";

class TopBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            foundItems: props.foundItems,
            searchString: props.searchString,
            autoScroll: props.autoScroll
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState(nextProps);
    }

    render ()
    {
        let refresh = 'index.html?'+Math.random();

        return (
            <nav id="topbar">

                <a id="logo" href={refresh}>Schweizerisches Sozialarchiv</a>

                <SearchBar
                    foundItems={this.state.foundItems}
                    searchString={this.state.searchString}
                    autoScroll={this.state.autoScroll}
                />

            </nav>
        )
    }



}

export default TopBar;

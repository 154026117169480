import React from "react";
import InfoIntro from './InfoIntro.js';
import JournalLendingInfo from "./Info/JournalLendingInfo";
import JournalOnlineInfo from "./Info/JournalOnlineInfo";


class Info extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            currentCoverId: props.currentCoverId
        }

    }

    componentWillReceiveProps(nextProps) {
        this.setState(nextProps);
    }

    render ()
    {
        if(this.state.currentCoverId === -1) {
            return (
                <InfoIntro/>
            )
        }

        var item = global.items[this.state.currentCoverId];

        let accompanyingText = item.accompanying_text;
        if (accompanyingText !== null && accompanyingText.toLowerCase() === "xxx") {
            accompanyingText = '';
        }

        return (
            <div id="info-container">

                <div id="journal-info">

                    <div id="journal-main-info" className="info-box">
                        <div id="journal-title">{item.title}</div>
                        <div id="journal-accompanying-text">{accompanyingText}</div>
                    </div>

                    <JournalLendingInfo item={item} />

                    <JournalOnlineInfo item={item} />

                </div>
            </div>
        )
    }

}

export default Info;

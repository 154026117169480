import React from "react";

class Zoom extends React.Component {

    constructor(props) {

        super(props);

        this.state = {coverSize: props.coverSize};

        this.switchToSmall = this.switchToSmall.bind(this);
        this.switchToBig = this.switchToBig.bind(this);
        this.switch = this.switch.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState(nextProps);
    }

    switch() {
        if (this.state.coverSize === 'small') {
            this.switchToBig();
        } else {
            this.switchToSmall();
        }
    }

    switchToSmall() {
        global.ee.emit('update-cover-size', 'small');
    }

    switchToBig() {
        global.ee.emit('update-cover-size', 'big');
    }

    render ()
    {
        return (
            <div id="zoom">
                <div id="switch-to-small" onClick={this.switchToSmall}>Titel klein</div>
                <div id="switch" className={this.state.coverSize} onClick={this.switch} />
                <div id="switch-to-big" onClick={this.switchToBig}>Titel gross</div>
            </div>
        )
    }

}

export default Zoom;

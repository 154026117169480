import React from 'react';
import ReactDOM from "react-dom";
import Main from './Main.js';

class Kernel {

    boot() {
        let url = '/data.json';
        this.loadJSON(url,
            function(data) {

                global.items = Object.values(data.covers);
                global.topics = Object.values(data.topics);

                ReactDOM.render(
                    (<Main />),
                    document.getElementById('root')
                );

            },
            function(xhr) { console.error(xhr); }
        );
    }

    loadJSON(path, success, error) {
        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                if (xhr.status === 200) {
                    if (success)
                        success(JSON.parse(xhr.responseText));
                } else {
                    if (error)
                        error(xhr);
                }
            }
        };
        xhr.open("GET", path, true);
        xhr.send();
    }

}

export default Kernel;
import React from "react";

const WAIT_INTERVAL = 300;

class SearchBar extends React.Component {


    constructor(props) {

        super(props);

        this.state = {
            searchString: props.searchString,
            foundItems: props.foundItems,
            autoScroll: props.autoScroll,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState(nextProps);
    }

    handleChange(event) {
        const date = new Date();
        const value = event.target.value;
        this.lastTyping = date;
        const t = this;
        t.setState({
            searchString: value
        });

        setTimeout(function() {

            if (date === t.lastTyping) {
                global.ee.emit('update-search', value);
            }
        }, WAIT_INTERVAL);

    }



    discard() {
        global.ee.emit('reset');
    }


    render ()
    {
        var journalsNumber = global.items.length;

        if (this.state.autoScroll) {
            this.refs.input.blur()
        }

        return (
            <div id="search-container">
                <input id="search" placeholder="Suchbegriff" value={this.state.searchString}  onChange={this.handleChange} ref="input" />
                <div id="search-discard" onClick={this.discard.bind(this)}/>
                <div id="search-number">{this.state.foundItems} / {journalsNumber}</div>
            </div>
        )

    }
}

export default SearchBar;

import React from "react";
import TopicBar from './TopicBar.js';
import TopBar from './TopBar.js';
import Zoom from './Zoom.js';
import Cover from './Cover.js';
import AutoScroll from './AutoScroll.js';
import TouchDevice from './TouchDevice.js';
import EventEmitter from 'events';
import './style/style.scss';

class Main extends React.Component {

    constructor(props) {

        super(props);

        this.cache = [];

        global.ee = new EventEmitter();

        this.buildSearchIndex();
        const search = this.search("", -1);

        this.state = {
            currentCoverId: -1,
            currentTopicId: -1,
            searchString: "",
            coverSize: "small",
            autoScroll: false,
            currentItems: search.i,
            topicCount: search.t,
            foundItems: search.i.length
        };

        if (TouchDevice.isTouchDevice()) {
            let autoScroll = new AutoScroll();
            autoScroll.start();
        }

    }

    componentDidMount()
    {
        global.ee.addListener('reset', this.reset.bind(this));
        global.ee.addListener('update-topic', this.updateTopic.bind(this));
        global.ee.addListener('update-search', this.updateSearch.bind(this));
        global.ee.addListener('update-cover-size', this.updateCoverSize.bind(this));
        global.ee.addListener('select-cover', this.selectCover.bind(this));
        global.ee.addListener('stop-autoscroll', this.stopAutoScroll.bind(this));
        global.ee.addListener('start-autoscroll', this.startAutoScroll.bind(this));
    }

    updateTopic(currentTopicId) {
        const search = this.search(this.state.searchString, currentTopicId);

        this.setState({
            currentTopicId,
            currentCoverId: -1,
            currentItems: search.i,
            topicCount: search.t,
            foundItems: search.i.length,
        });
    }


    stopAutoScroll() {
        this.setState({
            autoScroll: false,
        });
    }

    startAutoScroll() {
        this.reset();
    }


    updateCoverSize(size) {
        this.setState({
            coverSize: size
        }, () => {
            if (this.state.currentCoverId !== -1) {
                let currentCover = document.getElementById("cover-"+this.state.currentCoverId);
                let currentCoverPosition = currentCover.offsetLeft;
                document.getElementById("cover-container").scrollLeft = currentCoverPosition;
            }
        });

    }

    updateSearch(searchString) {
        let search = this.search(searchString, this.state.currentTopicId);

        this.setState({
            searchString,
            currentCoverId: -1,
            currentItems: search.i,
            topicCount: search.t,
            foundItems: search.i.length
        });
    }

    selectCover(coverId) {
        if (coverId === this.state.currentCoverId) {
            // deselect
            coverId = -1;
        }

        this.setState({currentCoverId: coverId});
    }

    toSearchWord(input) {
        return input.toLowerCase().trim().replace("-", "").replace("<<", "").replace(">>", "");
    }

    buildSearchIndex() {

        this.stopWords = [
            'des',
            'der',
            'das',
            'und',
            'für',
            'ein',
            'einer',
            'eine',
            '&',
            '-'
        ];

        let searchIndex = {};

        for (var i = 0; i < global.items.length; i++) {

            let title = global.items[i].title;
            let words = this.toSearchWord(title).split(" ");
            if (title.gnd_sach_aleph !== null) {
                let gndTopicalTerms = title.gnd_sach_aleph;
                for (const j in gndTopicalTerms) {
                    words.push(this.toSearchWord(gndTopicalTerms[j]));
                }
            }
            if (title.gnd_org_aleph !== null) {
                let gndTopicalTerms = title.gnd_org_aleph;
                for (const j in gndTopicalTerms) {
                    words.push(this.toSearchWord(gndTopicalTerms[j]));
                }
            }
            if (title.gnd_geo_aleph !== null) {
                let gndTopicalTerms = title.gnd_geo_aleph;
                for (const j in gndTopicalTerms) {
                    words.push(this.toSearchWord(gndTopicalTerms[j]));
                }
            }
            // make unique
            words = words.filter((v, i, a) => a.indexOf(v) === i);
            for (let j in words) {
                let word = words[j];

                if (word === "" || this.stopWords.includes(this.stopWords)) {
                    continue;
                }

                if (searchIndex[word] === undefined) {
                    searchIndex[word] = [];
                }

                searchIndex[word].push(i);
            }
        }

        this.searchIndex = searchIndex;
    }


    search(searchString, currentTopicId) {
        let search = this.toSearchWord(searchString);
        let searchWords = search.split(" ").sort();
        // make unique
        searchWords = searchWords.filter((v, i, a) => a.indexOf(v) === i);
        for (let i in searchWords) {
            let searchWord = searchWords[i];
            if (this.stopWords.includes(searchWord)) {
                let index = searchWords.indexOf(searchWord);
                if (index > -1) {
                    searchWords.splice(index, 1);
                }
            }
        }
        search = searchWords.join(" ");

        const searchId = currentTopicId + '_' + search;

        if (this.cache[searchId] !== undefined) {
            return this.cache[searchId];
        }

        let currentItems = [];
        const currentItemsPerSearchWord = [];
        let topicCount = this.getEmptyTopicCount();


        if (search === '') {
            global.items.map((item, i) => {

                item.topics.map((topic, j) => {
                    topicCount[topic]++;

                    return topic;
                });

                if (currentTopicId !== -1) {
                    if (!item.topics.includes(currentTopicId)) {
                        return item;
                    }
                }

                currentItems.push(i);

                return item;
            });
        } else {

            for (let word in this.searchIndex) {

                for (let j in searchWords) {

                    if (currentItemsPerSearchWord[j] === undefined) {
                        currentItemsPerSearchWord[j] = [];

                    }
                    let searchWord = searchWords[j];

                    if (word.includes(searchWord)) {
                        currentItemsPerSearchWord[j] = currentItemsPerSearchWord[j].concat(this.searchIndex[word]);
                    }
                }
            }

            currentItems = currentItemsPerSearchWord[0];
            if (searchWords.length > 1) {
                for (let k = 0; k < searchWords.length - 1; k++) {
                    currentItems = currentItems.filter((n) => currentItemsPerSearchWord[k+1].includes(n));
                }
            }

            // unique array
            currentItems = currentItems.filter((v, i, a) => a.indexOf(v) === i);

            let newCurrentItems = [];
            for(let l in currentItems) {
                let key = currentItems[l];
                var item = global.items[key];

                if (currentTopicId !== -1) {
                    if (!item.topics.includes(currentTopicId)) {
                        continue;
                    }
                }

                newCurrentItems.push(key);
                item.topics.map((topic, j) => {
                    topicCount[topic]++;

                    return topic;
                });
            }
            currentItems = newCurrentItems;
        }


        const result = {
            i: currentItems,
            t: topicCount
        };

        if (Object.keys(this.cache).length > 2000 ) {
            this.cache = {};
        }

        this.cache[searchId] = result;

        return result;

    }

    reset() {
        let search = this.search("", -1);

        this.setState({
            autoScroll: true,
            coverSize: "small",
            searchString: "",
            currentTopicId: -1,
            currentCoverId: -1,
            currentItems: search.i,
            topicCount: search.t,
            foundItems: search.i.length
        });
    }

    render ()
    {
        let className = '';
        if (TouchDevice.isTouchDevice()) {
            className += ' touch';
        }

        return (
            <div className={className}>
                <Zoom coverSize={this.state.coverSize} />
                <Cover
                    currentCoverId={this.state.currentCoverId}
                    currentTopicId={this.state.currentTopicId}
                    currentItems={this.state.currentItems}
                    coverSize={this.state.coverSize}
                />
                <TopicBar
                    currentTopicId={this.state.currentTopicId}
                    currentCoverId={this.state.currentCoverId}
                    topicCount={this.state.topicCount}
                />
                <TopBar
                    foundItems={this.state.foundItems}
                    searchString={this.state.searchString}
                    autoScroll={this.state.autoScroll}
                />
            </div>
        )
    }

    getEmptyTopicCount() {
        return {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0,
            12: 0,
            13: 0,
            14: 0
        };
    }
}

export default Main;

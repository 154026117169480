class AutoScroll  {


    start()
    {
        this.autoScrollPosition = false;
        this.lastInterection = new Date();

        var t = this;

        /*$(document).on('touchstart', function (e) {
            t.lastInterection = new Date();
            t.autoScrollPosition = false;
        });
        */

        document.body.onclick = function() {
            t.lastInterection = new Date();
            t.autoScrollPosition = false;
        };

        t.autScroll();
    }

    autScroll() {

        if (this.autoScrollPosition !== false) {
            this.scroll();
        } else {
            var now = new Date();
            var timeSinceLastInterection = now.getTime() - this.lastInterection.getTime();
            // move after 300 sec inactivity
            var waitingTime = 300 * 1000;
            if (timeSinceLastInterection > waitingTime) {
                if (this.autoScrollPosition === false) {
                    global.ee.emit('start-autoscroll');
                    //this.autoScrollPosition = $("#cover-container").scrollLeft();
                    this.autoScrollPosition = document.getElementById("cover-container").scrollLeft;
                }
                this.scroll();
            } else {
                global.ee.emit('stop-autoscroll');
                this.autoScrollPosition = false;
                setTimeout(this.autScroll.bind(this), waitingTime - timeSinceLastInterection);
            }
        }

    }

    scroll()
    {
        //let maxScrollWidth = $("#cover-container").prop('scrollWidth') ;
        let maxScrollWidth = document.getElementById("cover-container").scrollWidth;

        this.autoScrollPosition += 1;
        // go to 0 if scroll end reached
        if (this.autoScrollPosition + window.innerWidth > maxScrollWidth) {
            this.autoScrollPosition = 0;
        }
        //$("#cover-container").scrollLeft(this.autoScrollPosition);
        document.getElementById("cover-container").scrollLeft = this.autoScrollPosition;
        setTimeout(this.autScroll.bind(this), 35);
    }


}

export default AutoScroll;
